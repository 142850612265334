var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "450", height: "80", zIndex: "99999" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", {}, [_vm._v("Original Name")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:50",
                    expression: "'required|max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Original Name" },
                model: {
                  value: _vm.originalNameInEdit,
                  callback: function ($$v) {
                    _vm.originalNameInEdit = $$v
                  },
                  expression: "originalNameInEdit",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Original Name"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveLocation },
            },
            [_vm._v(_vm._s(_vm.locationId > 0 ? "Update" : "Add"))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }