var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-3", staticStyle: { color: "#636363" } }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v("Edit Location Normalisation"),
        ]),
      ]),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "flex flex-wrap justify-start mb-3" }),
          _c("h3", { staticStyle: { color: "#636363" } }, [
            _vm._v(_vm._s(_vm.normalisedName)),
          ]),
          _c("div", { staticClass: "text-muted mt-2" }, [
            _vm._v("Country Code: " + _vm._s(_vm.countryCode)),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start mt-4 mb-3" },
            [
              _c(
                "vs-button",
                { staticClass: "sm:mb-0 mb-2", on: { click: _vm.addLocation } },
                [_vm._v("Add")]
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.locationList,
              gridOptions: _vm.gridOptions,
              rowSelection: "single",
              pagination: false,
              context: _vm.context,
              suppressPaginationPanel: true,
            },
            on: { "first-data-rendered": _vm.onFirstDataRendered },
          }),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end mt-3" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                { staticClass: "mt-4", on: { click: _vm.saveAll } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showLocationEditor
        ? _c("location-normalised-name-editor", {
            attrs: {
              locationId: _vm.locationIdInEdit,
              originalName: _vm.originalNameInEdit,
            },
            on: {
              onAddSuccess: _vm.onAddSuccess,
              onEditSuccess: _vm.onEditSuccess,
              onCancel: function ($event) {
                _vm.showLocationEditor = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }